.body-app {
  background-color: #333;
}

.body-container {
  width: 84%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 10px 80px 0 10px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  grid-auto-rows: 40px auto auto auto auto;
  grid-gap: 20px;
}

.item-0 {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / span 1;
}

.item-1,
.item-2,
.item-3,
.item-4 {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / span 1;
}

.item-5 {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / span 2;
}

@media only screen and (min-width: 500px) {
  .body-container {
    scrollbar-width: thin;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    grid-auto-rows: 40px 1fr 1fr;
  }
  .item-0 {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / span 2;
  }
  .item-1 {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / span 1;
  }
  .item-2 {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / span 1;
  }
  .item-3 {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / span 1;
  }
  .item-4 {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / span 1;
  }
  .item-5 {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / span 2;
  }
}

@media only screen and (min-width: 850px) {
  .body-container {
    scrollbar-width: thin;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    grid-auto-rows: 40px 1fr 1fr;
  }
  .item-0 {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / span 2;
  }
  .item-1 {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / span 1;
  }
  .item-2 {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / span 1;
  }
  .item-3 {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / span 1;
  }
  .item-4 {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / span 1;
  }
  .item-5 {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / span 2;
  }
  .body-container::-webkit-scrollbar {
    overflow-y: hidden;
  }
}

.right-menu {
  display: block;
  position: fixed;
  top: 0%;
  right: 0px;
  width: 50px;
  height: 100%;
  z-index: 20;
  background-color: #292929;
  border-left: #585858 2px solid;
  text-decoration: none;
  -webkit-box-shadow: -3px 0px 3px rgba(71, 71, 71, 0.4);
          box-shadow: -3px 0px 3px rgba(71, 71, 71, 0.4);
}

.right-menu div {
  padding: 10px 0 0 0px;
  text-align: center;
}

.right-menu a {
  color: #999;
  text-decoration: none;
}

/* Body Styling Card */
.card {
  min-height: 100%;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-decoration: none;
  color: #dfdfdf;
  position: relative;
  top: 0;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  border: #e4e3e3 0px solid;
}

.card-item0 {
  font-weight: bold;
  color: #d8d6d6;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 0 0 0 0;
  text-align: center;
}

.card-item0 h6 {
  margin-top: 0;
}

.card:hover {
  top: -2px;
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
}

.card article {
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.card .thumb {
  padding-bottom: 60%;
  margin-left: 5%;
  margin-right: 5%;
  background-size: cover;
  background-position: center center;
}

.card img {
  height: 200px;
  margin-left: 1%;
  margin-right: 1%;
  -ms-flex-line-pack: distribute;
      align-content: space-around;
}

.card p {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  /* make p grow to fill available space*/
  line-height: 1.4;
}

.card h6 {
  color: white;
  background-color: red;
}

.card button {
  padding: 1px 5px 3px 5px;
  text-decoration: none;
  font-size: 16px;
  border-radius: 3px;
  background-color: white;
  color: black;
  border: 1px solid #a8a8a8;
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
  margin-top: 7px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.card button:hover {
  background-color: #e7e7e7;
}

/* typography */
h1 {
  font-size: 30px;
  margin: 0;
  color: #fafafa;
  text-shadow: 2px 2px 2px #dadada;
}

.card span {
  font-weight: bold;
  color: #999;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 2em 0 0 0;
}

.coma-blue {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}

.envelops-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 38px;
  height: 25px;
  border-radius: 5%;
  margin-left: 5px;
  position: relative;
  cursor: pointer;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.outline {
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 3px solid white;
  border-radius: 5%;
}

.menu-tabs-layout {
  position: relative;
  top: -50px;
}

.menu-tabs-text {
  position: relative;
  top: 10px;
}

.body-parallax {
  margin: 0;
}

.wrapper-parallax {
  height: 100vh;
  overflow-y: auto;
  overflow-x: scroll;
  -webkit-perspective: 10px;
          perspective: 10px;
}

header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  z-index: -1;
}

.background-parallax {
  -webkit-transform: translateZ(-15px) scale(3);
          transform: translateZ(-15px) scale(3);
}

.foreground-parallax {
  -webkit-transform: translateZ(-5px) scale(2.6);
          transform: translateZ(-5px) scale(2.6);
}

.background-parallax,
foreground-parallax {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
}

.title-parallax {
  position: fixed;
  text-align: center;
  font-size: 3rem;
  color: white;
  text-shadow: 10px 10px 18px black;
}

@media only screen and (min-width: 500px) {
  .title-parallax {
    font-size: 7rem;
  }
}

section {
  margin-top: -100px;
  font-size: 1rem;
  padding: 3rem;
  margin-right: 25px;
  background-color: #333;
  color: white;
  text-align: justify;
}

.p-parallax {
  color: #858585;
}
