// HOME HOME SASS
// HTML CSSResult Skip Results Iframe EDIT ON html {
//     background: #777777;
//     font-family: "Roboto", sans-serif;
//     -webkit-font-smoothing: antialiased;
//     padding: 10px 0 0 0;  
// }
.body-app {
    background-color: #333;
}

// Responsive setting of GRID containers depending on the size of the user's device (the smallest)
.body-container { 
    //background-color: #999;  
    width: 84%;
    max-width: 1240px;
    margin: 0 auto;    
    padding: 10px 80px 0 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 40px auto auto auto auto;
    grid-gap: 20px;   
    
}

.item-0 {
    grid-column: 1 / span 1;
}
.item-1,
.item-2,
.item-3,
.item-4 {
    grid-column: 1 / span 1;
}
.item-5 {
    grid-column: 1 / span 2;
}

// Responsive setting of GRID containers depending on the size of the user's device (up 500px devices)
@media only screen and (min-width: 500px) {
    .body-container {
        scrollbar-width: thin;         
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 40px 1fr 1fr;
    }
    .item-0 {
        grid-column: 1 / span 2;
    }
    .item-1 {
        grid-column: 1 / span 1;
    }
    .item-2 {
        grid-column: 2 / span 1;
    }
    .item-3 {
        grid-column: 1 / span 1;
    }
    .item-4 {
        grid-column: 2 / span 1;
    }
    .item-5 {
        grid-column: 1 / span 2;
    }
}

// Responsive setting of GRID containers depending on the size of the user's device (up 850px devices)
@media only screen and (min-width: 850px) {
    .body-container { 
        scrollbar-width: thin;                  
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 40px 1fr 1fr;
    }
    .item-0 {
        grid-column: 1 / span 2;
    }
    .item-1 {
        grid-column: 1 / span 1;
    }
    .item-2 {
        grid-column: 2 / span 1;
    }
    .item-3 {
        grid-column: 1 / span 1;
    }
    .item-4 {
        grid-column: 2 / span 1;
    }
    .item-5 {
        grid-column: 1 / span 2;
    }
    .body-container::-webkit-scrollbar {
        overflow-y: hidden;
      }
}

.right-menu {           
    display: block;
    position: fixed;
    top: 0%;
    right: 0px;
    width: 50px;
    height: 100%;
    z-index: 20;
    background-color: rgb(41, 41, 41);
    border-left: rgb(88, 88, 88) 2px solid;
    text-decoration: none;
    box-shadow: -3px 0px 3px rgba(71, 71, 71, 0.4);
}
.right-menu div {
    padding: 10px 0 0 0px;
    text-align: center;
}
.right-menu a {
    color: #999;
    text-decoration: none;
}

/* Body Styling Card */
.card {
    min-height: 100%;
    //background: white;
    //     background: rgb(0,0,0);
    // background: linear-gradient(0deg, rgba(31, 31, 31, 0.9) 5px, rgba(255, 255, 255, 1) 8px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: rgb(223, 223, 223);
    position: relative;
    top: 0;
    transition: all 0.1s ease-in;
    border: rgb(228, 227, 227) 0px solid;
}
.card-item0 {
    font-weight: bold;
    color: rgb(216, 214, 214);
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin: 0 0 0 0;
    text-align: center;
}
.card-item0 h6 {
    margin-top: 0;    
}

.card:hover {
    top: -2px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
}

.card article {
    padding: 5px;
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;
}
.card .thumb {
    padding-bottom: 60%;
    margin-left: 5%;
    margin-right: 5%;
    background-size: cover;
    background-position: center center;
}
.card img{
    height: 200px;
    margin-left: 1%;
    margin-right: 1%;
    align-content: space-around;
}

.card p {
    flex: 1; /* make p grow to fill available space*/
    line-height: 1.4;
}
.card h6 {
    color: white;
    background-color: red;
}
.card button {
    padding: 1px 5px 3px 5px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 3px;
    background-color: white;
    color: black;
    border: 1px solid #a8a8a8;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
    margin-top: 7px;
    margin-bottom: 5px;
    margin-right: 5px;
}
.card button:hover {
    background-color: #e7e7e7;
}

/* typography */
h1 {
    font-size: 30px;
    margin: 0;
    color: rgb(250, 250, 250);
    text-shadow: 2px 2px 2px rgb(218, 218, 218);
}

.card span {
    // font-size: 12px;
    font-weight: bold;
    color: #999;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin: 2em 0 0 0;
}
.coma-blue {
    display: flex;
    flex-direction: column;
    position: relative;
}
.envelops-contact {
    display: flex;
}

// SVG Shadows
// .shadow {
//     -webkit-filter: drop-shadow( 4px 4px 3px rgba(255, 255, 255, 0.7));
//     filter: drop-shadow( 4px 4px 3px rgba(226, 226, 226, 0.7));
//   }
//   .filter {
//     // filter: drop-shadow( 10px 12px 10px rgb(48, 48, 48) );
//     filter: text-shadow( 5px 5px 0px rgb(48, 48, 48) );
// }

// Styles for animations with layoutId in NavBar
.item {
    display: flex;
    width: 38px;
    height: 25px;
    border-radius: 5%;
    margin-left: 5px;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
}

.outline {
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;    
    border: 3px solid white;    
    border-radius: 5%;
}
.menu-tabs-layout {
    position: relative;
    top: -50px;
}
.menu-tabs-text {
    position: relative;
    top: 10px;
}

// Styles for Parallax page
.body-parallax {
    margin: 0;
}
.wrapper-parallax {
    height: 100vh;
   overflow-y: auto;
    overflow-x:scroll;
    perspective: 10px;
}
header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    transform-style: preserve-3d;
    z-index: -1;
}
.background-parallax {
    transform: translateZ(-15px) scale(3);
}
.foreground-parallax {
    transform: translateZ(-5px) scale(2.6);
}

.background-parallax,
foreground-parallax {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
}
.title-parallax {
    position: fixed;
    text-align: center;    
    font-size: 3rem;
    color: white;
    text-shadow: 10px 10px 18px black;
    
}
@media only screen and (min-width: 500px) {
    .title-parallax {
        font-size: 7rem;        
    }
}

section {
    margin-top: -100px;
    font-size: 1rem;
    padding: 3rem;
    margin-right: 25px;
    background-color: #333;
    color: white;
    text-align: justify;
}
.p-parallax {
    color: rgb(133, 133, 133);
}
